@media screen and (max-width: 640px){
  .topLeft a{display:none}
  .topRight ul {display:none}
}


.top {
    width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: "Josefin Sans", sans-serif;
    z-index: 999;
  }
  
  .topLeft,
  .topRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .topCenter {
    flex: 6;
  }
  
  .topIcon {
    font-size: 20px;
    margin-right: 10px;
    color: #444;
    cursor: pointer;
  }
  
  .topImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
  
  .topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .topListItem {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
  }
  
  .topSearchIcon {
    font-size: 18px;
    color: #666;
    cursor: pointer;
    margin-left: 15px;
  }

  .link {
    text-decoration: none;
    color:black;
  }

  .logo{
    width:25px;
    height:auto;
    border-radius: 20px;
  }

  label{
    color:rgba(128, 128, 128, 0.747)
  }