.about p {
    font-weight:360;
  }

.about {
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    

}
.box1.sb8 {
  margin-left:30px
}

  .box1 {
    width: 70vw;
    margin: 30px;
    border: 4px solid #00bfb6;
    padding: 30px;
    text-align: left;
    font-weight: 900;
    color: black;
    font-family: arial;
    position: relative;
  }
  .sb8:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #00bfb6;
    border-top: 10px solid #00bfb6;
    border-bottom: 10px solid transparent;
    right: 20px;
    bottom: -23px;
  }

  .header{
    font-size:20px;
    color:#00bfb6;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }