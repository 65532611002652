.header {
    margin-top: 60px;
  }
  
  .headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Lora", serif;
    color: rgba(248, 244, 244, 0.99);
    
  }
  
  .headerTitleSm {
    position: absolute;
    top: 18%;
    font-size: 20px;
    color:black;
  }
  
  .headerTitleLg {
    position: absolute;
    top: 20%;
    font-size: 100px;
    text-shadow: 3px 0 black;
  }
  
  .headerImg{
      width: 100%;
      height: 450px;
      margin-top: 80px;
      object-fit: cover;
  }